import { PaginatedTableFilters } from 'common/hooks/table/usePaginatedTable';
import type { CIRulesEngine } from 'module/activity/components/cirulesengine';
import { BranchingLogicResponse, DataRule, ExecutionOptions, NormalizedValues } from 'module/activity/types/types';
import { QuerySortType } from 'module/patient/query/types/types';
import { ConfirmDateDialogValues, TranscriptionDialogValues } from 'module/patient/transcription/types/types';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export enum ButtonOperation {
    CLOSE,
    QUIT,
    RESUME,
    BEGIN,
    CLOSE_INCOMPLETE,
}

export interface EditCheckSubItem {
    pageId: string;
    questionId: string;
    questionSk: string;
    checkId: string;
    checkText: string;
    overrideReasonId: string;
}

export interface EditCheck {
    pk: string;
    sk: string;
    attachedToSk?: string;
    attachedToVersion?: number;
    editChecks: EditCheckSubItem[];
    version: number;
    isDeleted: number;
}

export interface DCElement {
    selected?: boolean;
    reason: string;
    fieldName: string;
    initialValue: string;
    newValue: string;
    otherReason?: string;
    fieldTitle: string;
    elementId?: string;
}

export type DataChangeElement = { dataChanges?: DCElement[]; editChecks?: EditCheckSubItem[]; errors?: DataRule[] };

export type InvalidAnswersDialogProps = {
    prevAnswer?: NormalizedValues;
    branchedElements: BranchingLogicResponse;
    previousAnswers: NormalizedValues[];
    normalizedValues: NormalizedValues;
    submitChange: () => void;
    submitCancel: () => void;
};

export enum ActivityOptions {
    Patient = 'Patient',
    Caregiver = 'Caregiver',
    Transcription = 'Transcription',
}

export interface BaseActivityProps {
    handleNavigationResults: ExecutionOptions['onNavTrigger'];
    handleDataUpdate: ExecutionOptions['onDataChange'];
    handleKeyUp: ExecutionOptions['onFieldKeyUp'];
    rulesEngine: MutableRefObject<CIRulesEngine>;
    setLoadedElements?: Dispatch<SetStateAction<string[]>>;
    loadedElements?: string[];
}

export enum TranscriptionDialogType {
    DATE = 'DATE',
    TRANSCRIPT = 'TRANSCRIPT',
}

export type TranscriptionDialogProps = {
    type: TranscriptionDialogType;
    status?: string;
    canTranscribe?: boolean;
    isUntriggeredActivity?: boolean;
    isActivity?: boolean;
    isEdit?: boolean;
    showOnlyTranscribedFields?: boolean;
};

export type ActivityOptionsDialogProps = {
    avoidTranscribe?: boolean;
};

export type TranscriptionActivityFormVals = ConfirmDateDialogValues & TranscriptionDialogValues;

export enum QueryParams {
    VisitId = 'VisitId',
    ActivityId = 'ActivityId',
    PageId = 'PageId',
    PatientId = 'PatientId',
}

export enum ActivityInfoTabsEnum {
    QUERY = 'QUERY',
    DCR = 'DCR',
    REVIEW = 'REVIEW',
    REVISION = 'REVISION',
    NONE = 'NONE',
}

export interface ActivityInfoDrawerProps {
    defaultTab?: ActivityInfoTabsEnum;
    questionText?: string;
    questionId?: string;
    filters?: PaginatedTableFilters;
    sort?: QuerySortType;
    queryAdded?: string;
}

export enum SiteActivityType {
    SITE = 'site',
    HOME = 'home',
    DCR = 'dcr',
}

export type ActivityOptionValues = { launchAction?: ActivityOptions };

export enum ActivityDialogType {
    OPTIONS = 'OPTIONS',
    TRANSCRIPTION = 'TRANSCRIPTION',
}

export type EditActivityValues = {
    values?: Record<string, unknown>;
    bypassTranscription?: boolean;
    launchTranscription?: boolean;
};
