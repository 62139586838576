import { setGlobalDateLocale } from '@orion/fe-utils';
import classNames from 'classnames';
import { importDateLocale } from 'common/utils/datetime';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { includes, isNil, map, noop, replace, startsWith, toLower } from 'lodash';
import { initReactI18next, withTranslation } from 'react-i18next';
import { LanguageDirectionClass } from './types';

export const getCurrentLng = (): string => i18next.language || window.localStorage.i18nextLng || '';

function currentLangEquals(targetLang: string): boolean {
    //TODO: Ensure we handle region specific versions of languages
    return startsWith(getCurrentLng(), targetLang);
}

const RTLLanguages = [
    'he', // Hebrew
    'ha', // Hausa
    'ps', // Pashto
    'ur', // Urdu
    'az', // Azerbaijani
    'fa', // Persian/Farsi
    'jv', // Javanese
    'ks', // Kashmiri
    'kk', // Kazakh
    'ku', // Kurdish
    'sd', // Sindhi
    'yi', // Yiddish
    'dv', // Maldivian/Divehi
    'ar', // Arabic
];

export const isRTL = (lng?: string): boolean => {
    if (lng) {
        return includes(RTLLanguages, lng);
    } else {
        const isRTLArray = map(RTLLanguages, (RTLLanguage) => {
            return currentLangEquals(RTLLanguage);
        });
        return includes(isRTLArray, true);
    }
};

// @todo: Remove this once MomentJS is removed
const momentLocaleFileLangCode = {
    'sr-cs': 'sr-cyrl',
};

export const localeFileLangCode = {
    'sr-RS': 'sr-Latn',
};

export const getLibraryLangCode = (lang: string, isMomentDefault = true): string => {
    if (isMomentDefault) {
        const languageKey = toLower(lang);
        return momentLocaleFileLangCode[languageKey] || languageKey;
    }
    return localeFileLangCode[lang] || lang;
};

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            load: 'currentOnly',
            fallbackLng: 'en-US',
            lowerCaseLng: false,
            // patientApp translations contain not just patientApp module translations but all patient facing translations across all modules
            ns: ['global', 'patientApp'],
            fallbackNS: ['global', 'patientApp'],
            defaultNS: 'global',
            backend: {
                // HttpApi
                loadPath: '/modules/{{ns}}/i18n-{{lng}}.json',
            },
            detection: {
                // LanguageDetector
                order: ['querystring', 'cookie', 'localStorage', 'navigator'],
            },
            interpolation: {
                escapeValue: false,
                skipOnVariables: true,
            },
        },
        async (): Promise<void> => {
            const { language } = i18next;
            const isDefaultLocale = language === 'en-US' || language === 'en';
            if (!isDefaultLocale && language !== 'cimode') {
                // Trigger any 3rd party lib translations to load
                const languageKey = getLibraryLangCode(language);
                await importDateLocale(languageKey);
            }

            const dir = isRTL() ? 'rtl' : 'ltr';
            const body = document.getElementsByTagName('body')[0];

            body.className = classNames(body.className, dir);
            body.dir = dir;
            document.documentElement.dir = dir;
            document.documentElement.lang = language;
        }
    );

export { i18next as i18n };

export function setLanguage(lng: string, postChangeCallback = noop): void {
    const language = lng?.substring(0, 2);

    i18next.changeLanguage(lng, async (): Promise<void> => {
        const dir = isRTL(language) ? 'rtl' : 'ltr';
        const body = document.getElementsByTagName('body')[0];

        body.className = classNames(replace(body.className, body.dir, dir));
        body.dir = dir;
        document.documentElement.dir = dir;
        document.documentElement.lang = lng;

        const languageKey = getLibraryLangCode(lng);
        if (languageKey !== 'en-us' && languageKey !== 'en' && languageKey !== 'cimode') {
            await importDateLocale(languageKey);
        }

        setGlobalDateLocale([languageKey, languageKey?.substring(0, 2)]);
        await postChangeCallback(lng);
    });
}

// @todo: Remove this once the refactor is completed
// Changes the app language from patient's preferred language to the app language that it was previously in
export const resetApplicationLanguage = (): void => {
    const currentLang = getCurrentLng();
    const applicationLanguage = localStorage.getItem('applicationLanguage');

    if (!isNil(applicationLanguage) && currentLang?.substring(0, 2) !== applicationLanguage?.substring(0, 2)) {
        setLanguage(applicationLanguage);
        localStorage.removeItem('applicationLanguage');
    }
};

export const getLanguageDirectionClass = (language?: string): LanguageDirectionClass => {
    return isRTL(language) ? 'isRTLLanguage' : 'isLTRLanguage';
};

export default withTranslation;
